import { Container } from "reactstrap";
import Activity from "../../images/activites.png"
import Banner from "../Header/Banner";
import Location from '../../images/situation.png'
import People from '../../images/population.png'
import Chart from '../../images/organigramme.png'


const AboutPage = () => {
    return (
        <Container>
            <div className="m-4">
                <Banner />
            </div>
            <div className="mt-2">
                <img src={Location} alt="Location" className="image-style"/>
            </div>
           
            <div className="mt-4">
                <img src={Activity} alt="Présentation" className="image-style" />
            </div>
            <div className="mt-4">
                <img src={People} alt="Présentation" className="image-style" />
            </div>
            <div className="mt-4">
                <img src={Chart} alt="Présentation" className="image-style" />
            </div>
             
        </Container>
   )
}

export default AboutPage;