
import { Row, Col } from 'reactstrap';
import flag from "../images/flag.jpg";

const Footer = () => {
    return (
        <div className='m-2'>
            <Row>
                <Col md={4}><img src={flag} alt="drapeau" style={{width:50}}/> <span className='color-primary text-bold'>République du Sénégal</span></Col>
                <Col md={4}><span className='text-lg text-center'>Commune de Ndiaganiao</span></Col>
                <Col md={4}><span className='text-lg' style={{float:"right"}}>© Ndiaganiao {new Date().getFullYear()}</span></Col>
            </Row>
      
      </div>
    )
}

export default Footer;