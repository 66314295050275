import { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem, NavLink } from 'reactstrap';
import flag from "../../images/flag.jpg";

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar color="light" light expand="md" style={{backgroundColor: "#e3f2fd"}}>
            <NavbarBrand href="/"><img src={flag} alt="drapeau" style={{width:50}}/> <span className='color-primary text-bold'>République du Sénégal - Commune de Ndiaganiao</span></NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="m-auto" navbar >
                    <NavItem > 
                        <NavLink href="/" className='color-primary text-bold'>Accueil</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/about" className='color-primary text-bold'>Présentation</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/projects" className='color-primary text-bold'>Projets</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/news" className='color-primary text-bold'>Actualités</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/goals" className='color-primary text-bold'>Stratégie</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
      </Navbar>
    )
}

export default Navigation