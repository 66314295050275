import { Container } from "reactstrap";
import Craftmanship from "../../images/craftsmanship.png"
import Banner from "../Header/Banner";
import Schools from '../../images/Schools.png'
import Roads from '../../images/Roads.png'
import Agriculture from '../../images/Agriculture.png'
import Footer from "../Footer";

const ProjectPage = () => {
    return (
        <Container>
            <div className="m-4">
                <Banner />
            </div>
            <div className="mt-2">
                <img src={Agriculture} alt="Agriculture" className="image-style"/>
            </div>
           
            <div className="mt-4">
                <img src={Schools} alt="Ecoles" className="image-style" />
            </div>
            <div className="mt-4">
                <img src={Roads} alt="Routes" className="image-style" />
            </div>
            <div className="mt-4">
                <img src={Craftmanship} alt="Artisanats" className="image-style" />
            </div>
             <Footer />
        </Container>
   )
}

export default ProjectPage;