import Banner from "./Header/Banner"
import Welcome from "./Header/Welcome"
import CarouselHome from "./CarouselHome"
import News from "./pages/News"
import Mayor from "./pages/Mayor"
import { Container } from "reactstrap"
import Footer from "./Footer"

const Home = () => {
    return (
        <Container>
            <div className="m-4">
                <Banner />
                <CarouselHome/>
            </div>
            <Mayor/>
            <News />
            <Welcome />
            <Footer />
        </Container>
    )
} 

export default Home