import { useState } from 'react';
import Slide1 from '../images/slide1.jpeg'
import Slide2 from '../images/slide2.jpeg'
import Slide3 from '../images/slide3.jpg'
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
  } from 'reactstrap';
  
  const items = [
    {
      src: Slide1,
      altText: 'Une terre Fertile',
      caption: 'Les meilleurs terres du sénégal sont a Ndiaganiao',
      key: 1,
    },
    {
      src: Slide2,
      altText: 'Ecole',
      caption: "Assurez un avenir meilleur a nos enfants",
      key: 2,
    },
    {
      src: Slide3,
      altText: 'Santé',
      caption: 'Profitez des meilleurs soins de santé',
      key: 3,
    },
  ];
  


const CarouselHome = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    
    <Carousel
      style={{width:"100%"}}
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
    
  );
}

export default CarouselHome;