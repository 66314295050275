import { Container } from "reactstrap";
import Banner from "../Header/Banner";
import CarouselHome from "../CarouselHome";
import News from "./News";
import Footer from "../Footer";

const NewsPage = () => {
    return (
        <Container>
             <div className="m-4">
                <Banner />
                <CarouselHome/>
            </div>
            <News />
            <Footer />
        </Container>
    )
}

export default NewsPage;