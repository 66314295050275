import '../../styles/global.css'
import Navigation from './Navigation'

const Banner = () => {

    return (
    <div>
        <div className="banner"></div>
        <Navigation />
      </div>
    )
}

export default Banner;