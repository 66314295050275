import { Col, Container, Row } from "reactstrap";
import mayor from '../../images/mayor.jpg'

const Mayor = () => {
    return (
        <Container className="mt-4">
            <h1 className="text-center">Mot du Maire de Ndiaganiao</h1>
            <Row className="mt-2">
                <Col md={5}>
                    <img src={mayor} alt="mairesse de Ndiaganiao" style={{width:"100%"}} />
                </Col>
                <Col md={7}>
                    <span style={{textAlign:"justify", fontSize:"30px"}} >Forte de plusieurs expériences acquises à des postes de responsabilité (DG, chef de mission et chef de projet), le
Maire travaille de concert avec une équipe municipale pour un développement durable de Ndiaganiao</span>
                </Col>
            </Row>
        </Container>
    )
}

export default Mayor;