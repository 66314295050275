  import { CardBody, CardText, CardSubtitle, CardTitle, Card, Container, Row, Col } from "reactstrap";
  const news = [{
    title: 'Un titre',
    subtitle: "Un sous titre",
    image: "https://picsum.photos/318/180",
    details: "Voici le détail de cet article"
  },
  {
    title: 'Un titre',
    subtitle: "Un sous titre",
    image: "https://picsum.photos/318/180",
    details: "Voici le détail de cet article"
  },
  {
    title: 'Un titre',
    subtitle: "Un sous titre",
    image: "https://picsum.photos/318/180",
    details: "Voici le détail de cet article"
  },
  {
    title: 'Un titre',
    subtitle: "Un sous titre",
    image: "https://picsum.photos/318/180",
    details: "Voici le détail de cet article"
  },
  {
    title: 'Un titre',
    subtitle: "Un sous titre",
    image: "https://picsum.photos/318/180",
    details: "Voici le détail de cet article"
  },
  {
    title: 'Un titre',
    subtitle: "Un sous titre",
    image: "https://picsum.photos/318/180",
    details: "Voici le détail de cet article"
  },
  {
    title: 'Un titre',
    subtitle: "Un sous titre",
    image: "https://picsum.photos/318/180",
    details: "Voici le détail de cet article"
  },
  {
    title: 'Un titre',
    subtitle: "Un sous titre",
    image: "https://picsum.photos/318/180",
    details: "Voici le détail de cet article"
  },

]
  const News = () => {
    return (
        <Container className="mt-2" style={{}}>
            <h1 className="text-center">Actualités de la commune</h1>
            <Row>
            {news.map(info => { return(
                <Col className="mt-3">
                    <Card style={{width: '18rem', borderColor:'#5f8c94'}}>
                        <CardBody>
                            <CardTitle tag="h5">{info.title}</CardTitle>
                            <CardSubtitle className="mb-2 text-muted" tag="h6">{info.subtitle}</CardSubtitle>
                        </CardBody>
                        <img alt="Card cap" src={info.image} width="100%" />
                        <CardBody>
                            <CardText>{info.details}</CardText>
                        </CardBody>
                    </Card>
                </Col>
            )})}
            </Row>
        </Container>
        
    )
  }

  export default News;