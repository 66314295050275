import '../../styles/global.css'
import Cartography from "../../images/cartographie-ndiaganio.png"
import { Container } from 'reactstrap';
const Welcome = () => {
    return (
        <Container className='mt-2'>
            <img src={Cartography} style={{backgroundRepeat: "no-repeat", width:"100%"}} />
        </Container>
        
    )
}

export default Welcome;